'use strict'
const _ = require('lodash')
const MASTER_PAGE_ID = 'masterPage'
const VARIANTS_LIST = 'VariantsList'
const MOBILE_VARIANT_ID = 'MOBILE-VARIANT'
const MOBILE_TYPE = 'Mobile'

const getVariantList = id => ({
    id,
    type: VARIANTS_LIST,
    componentId: MASTER_PAGE_ID,
    values: [`#${MOBILE_VARIANT_ID}`]
})

const mobileVariantData = {type: MOBILE_TYPE, componentId: MASTER_PAGE_ID, id: MOBILE_VARIANT_ID}

const addMobileVariantOnce = (structure, data, uniqueIdGenerator) => {
    const variantsData = data.variants_data || {}
    const hasMobileVariant = _.has(variantsData, MOBILE_VARIANT_ID)

    if (!hasMobileVariant) {
        const variantsListId = uniqueIdGenerator.getUniqueId('variants', '-')
        structure.variantsQuery = variantsListId
        variantsData[MOBILE_VARIANT_ID] = mobileVariantData
        variantsData[variantsListId] = getVariantList(variantsListId)
        data.variants_data = variantsData
    }
}
/**
 * @exports utils/dataFixer/plugins/addMobileVariantOnce
 * @type {{exec: function}}
 */
module.exports = {
    name: 'addMobileVariantOnce',
    version: 1,
    exec(pageJson, pageIdsArray, magicObject) {
        const {uniqueIdGenerator} = magicObject.dataFixerUtils
        const {structure, data} = pageJson
        const isMasterPage = structure.type === 'Document'

        if (isMasterPage) {
            addMobileVariantOnce(structure, data, uniqueIdGenerator)
        }
    }
}
