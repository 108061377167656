import {wait} from './setTimeout'
import {Attempt, asyncAttempt} from './function'

const DEFAULT_RETRIES = 3
const DEFAULT_WAIT = 0

export const taskWithRetries = async <T, E extends Error>(
    task: () => Promise<T>,
    shouldRetry: (error: E) => boolean = () => true,
    retries: number = DEFAULT_RETRIES,
    waitInterval: number = DEFAULT_WAIT
): Promise<T> => {
    try {
        return await task()
    } catch (error) {
        if (retries <= 0 || !shouldRetry(error as E)) {
            throw error
        }

        await wait(waitInterval)

        return await taskWithRetries(task, shouldRetry, retries - 1, waitInterval)
    }
}

export const taskWithRetriesAndInfo = async <T, E extends Error>(
    task: () => Promise<T>,
    shouldRetry: (error: E) => boolean = () => true,
    retries: number = DEFAULT_RETRIES,
    waitInterval: number = DEFAULT_WAIT
): Promise<{result: Attempt<T, E>; retries: number}> => {
    let result = await asyncAttempt<typeof task, E>(task)
    let attempts = 0
    while (result.didThrow && shouldRetry(result.error) && attempts < retries) {
        await wait(waitInterval)
        result = await asyncAttempt<typeof task, E>(task)
        attempts++
    }
    return {result, retries: attempts}
}
