type ReportTags = Record<string, any>
type ReportExtras = Record<string, any>

type ReportableIssueParamsWithoutSeverity = Omit<ReportableIssueParams, 'issueSeverity'>
export type IssueSeverity = 'error' | 'warning'
export type ReportableErrorParams = ReportableIssueParamsWithoutSeverity
export type ReportableWarningParams = ReportableIssueParamsWithoutSeverity
export type ReportableIssueType = ReportableWarningParams | ReportableErrorParams

export interface ReportableIssueParams {
    issueSeverity: IssueSeverity
    errorType: string
    message: string
    tags?: ReportTags
    extras?: ReportExtras
}

abstract class ReportableIssue extends Error {
    issueSeverity: IssueSeverity
    errorType: string
    tags?: ReportTags
    extras?: ReportExtras
    originalMessage?: string

    constructor(params: ReportableIssueParams) {
        super(params.message)
        this.issueSeverity = params.issueSeverity
        this.errorType = params.errorType
        this.tags = params.tags
        this.extras = params.extras

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ReportableIssue.prototype)
    }
}

export class ReportableError extends ReportableIssue {
    constructor(params: ReportableErrorParams) {
        super({issueSeverity: 'error', ...params})

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ReportableError.prototype)
    }
}

export class ReportableWarning extends ReportableIssue {
    constructor(params: ReportableWarningParams) {
        super({issueSeverity: 'warning', ...params})

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ReportableWarning.prototype)
    }
}

export type ReportableIssueParamsPartial = Partial<ReportableIssueParams> & Pick<ReportableIssueParams, 'errorType'>

export const getReportableFromError = (err: any, params: ReportableIssueParamsPartial) => {
    const originalMessage = err.message ?? err.toString()
    const message = params.message ? `${params.message}|${originalMessage}` : originalMessage
    let reportableIssue

    if (err instanceof ReportableWarning) {
        reportableIssue = new ReportableWarning({...params, message})
    } else {
        reportableIssue = new ReportableError({...params, message})
    }

    reportableIssue.originalMessage = originalMessage
    reportableIssue.stack = err.stack
    reportableIssue.tags = Object.assign({}, reportableIssue.tags, err.tags)
    reportableIssue.extras = Object.assign({}, reportableIssue.extras, err.extras)

    return reportableIssue
}
