import _ from 'lodash'

interface Option<T> {
    map<U>(f: (x: T) => U): Option<U>
    flatMap<U>(f: (x: T) => Option<U>): Option<U>
    getOrDefault(value: T): T
    getOrElse(f: () => T): T
    getOrThrow(err: any): T
    getOrElseOption(f: () => Option<T>): Option<T>
    get(): T
    isNone(): this is None<T>
    isSome(): this is Some<T>
    forEach(f: (x: T) => void): void
}

class Some<T> implements Option<T> {
    constructor(private value: T) {}

    getOrElseOption(): Option<T> {
        return this
    }

    map<U>(f: (x: T) => U): Option<U> {
        return new Some(f(this.value))
    }

    flatMap<U>(f: (x: T) => Option<U>): Option<U> {
        return f(this.value)
    }

    getOrDefault(): T {
        return this.value
    }

    getOrElse(): T {
        return this.value
    }

    getOrThrow(): T {
        return this.value
    }

    get(): T {
        return this.value
    }

    isNone(): this is None<T> {
        return false
    }

    isSome(): this is Some<T> {
        return true
    }

    forEach(f: (x: T) => void): void {
        f(this.value)
    }
}

class None<T> implements Option<T> {
    map<U>(): Option<U> {
        return none()
    }

    flatMap<U>(): Option<U> {
        return none()
    }

    getOrDefault(value: T): T {
        return value
    }

    getOrElseOption(f: () => Option<T>): Option<T> {
        return f()
    }

    getOrElse(f: () => T): T {
        return f()
    }

    getOrThrow(err: any): T {
        throw err
    }

    get(): T {
        throw new Error('Cannot get value from None')
    }

    isNone(): this is None<T> {
        return true
    }

    isSome(): this is Some<T> {
        return false
    }

    forEach(): void {}
}

const noneInstance = new None()

function none<T>(): Option<T> {
    return noneInstance as Option<T>
}

function some<T>(value: T): Option<T> {
    return new Some(value)
}

function fromNullable<T>(value: T | null | undefined): Option<T> {
    return _.isNil(value) ? none() : some(value)
}

function fromTruthValue<T>(value: T): Option<T> {
    return value ? some(value) : none()
}

function findOption<T>(arr: T[], predicate: (x: T) => boolean): Option<T> {
    return fromNullable(arr.find(predicate))
}

export {Option, some, none, fromNullable, fromTruthValue, findOption}
