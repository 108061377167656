import type {ComponentStructureVariant, MeshConversionResult, StructureToConvertToMesh} from '../types'
import type {AnyItemLayout, AbsoluteLayout, UnitSize, MeshGridItemLayout, CompRef} from '@wix/document-services-types'

type AbsoluteLayoutWithCompId = AbsoluteLayout & {
    componentId: string
}

interface RowThresholdItem {
    row: number
    start: number
}

interface BottomRelationsCalculationInfo {
    absoluteLayoutWithCompId: AbsoluteLayoutWithCompId
    lastCompToConsider: number
}

interface MeshGridInitialCalculationResults {
    gridLayouts: Record<string, MeshGridItemLayout>
    rowsThreshold: RowThresholdItem[]
    bottomRelationsCalculationInfo: BottomRelationsCalculationInfo[]
}

const LOCKED_DISTANCE = 70
const inRange = (x: number, start: number, end: number): boolean => x >= start && x < end
const getBottom = (layout: AbsoluteLayout): number => layout.y + layout.height
const getMidY = (layout: AbsoluteLayout): number => layout.y + layout.height / 2
const isBoundedY = (boundedLayout: AbsoluteLayout, boundingLayout: AbsoluteLayout): boolean => {
    return boundedLayout.y > boundingLayout.y && getBottom(boundedLayout) <= getBottom(boundingLayout)
}
const hasTopTopAnchor = (compAbsoluteLayout: AbsoluteLayout, siblingAbsoluteLayout: AbsoluteLayout): boolean =>
    isBoundedY(compAbsoluteLayout, siblingAbsoluteLayout) ||
    inRange(compAbsoluteLayout.y, siblingAbsoluteLayout.y, getMidY(siblingAbsoluteLayout))
const hasBottomTopAnchor = (compAbsoluteLayout: AbsoluteLayout, siblingAbsoluteLayout: AbsoluteLayout): boolean =>
    !isBoundedY(compAbsoluteLayout, siblingAbsoluteLayout) &&
    (inRange(compAbsoluteLayout.y, getMidY(siblingAbsoluteLayout), getBottom(siblingAbsoluteLayout)) ||
        inRange(
            compAbsoluteLayout.y,
            getBottom(siblingAbsoluteLayout),
            getBottom(siblingAbsoluteLayout) + LOCKED_DISTANCE
        ))

const createGridLayout = (marginTop: number, row: number): MeshGridItemLayout => ({
    margins: {
        top: {
            value: marginTop,
            type: 'px'
        }
    },
    gridArea: {
        rowStart: row,
        rowEnd: row + 1
    }
})

const createRows = (compAbsoluteLayoutsTopAsc: AbsoluteLayoutWithCompId[]): MeshGridInitialCalculationResults => {
    const absoluteLayouts: AbsoluteLayoutWithCompId[] = [...compAbsoluteLayoutsTopAsc]
    let previousLayout: AbsoluteLayoutWithCompId = absoluteLayouts.shift()!
    let previousCompMarginTop = previousLayout.y
    let currentRow = 1
    const gridLayouts: Record<string, MeshGridItemLayout> = {}
    const rowsThreshold: RowThresholdItem[] = [{row: currentRow, start: previousLayout.y}]
    const bottomRelationsCalculationInfo: BottomRelationsCalculationInfo[] = []
    gridLayouts[previousLayout.componentId] = createGridLayout(previousLayout.y, currentRow)

    for (const [i, layout] of absoluteLayouts.entries()) {
        let top = 0

        if (!hasTopTopAnchor(layout, previousLayout)) {
            currentRow += 1
            rowsThreshold.push({row: currentRow, start: layout.y})

            bottomRelationsCalculationInfo.push({absoluteLayoutWithCompId: layout, lastCompToConsider: i + 1})
        } else {
            top = previousCompMarginTop + layout.y - previousLayout.y
        }

        gridLayouts[layout.componentId] = createGridLayout(top, currentRow)

        previousCompMarginTop = top
        previousLayout = layout
    }

    return {
        gridLayouts,
        rowsThreshold,
        bottomRelationsCalculationInfo
    }
}

const calculateBottomRelationsForComp = (
    {componentId: pushedComponentId, ...pushedAbsoluteLayout}: AbsoluteLayoutWithCompId,
    absoluteLayoutsByTopAsc: AbsoluteLayoutWithCompId[],
    lastIndexToConsider: number,
    gridLayouts: Record<string, MeshGridItemLayout>
): void => {
    let foundAnchors = false
    let hasWedgeToComp = false

    for (let i = 0; i < lastIndexToConsider; i++) {
        const {componentId: pushingComponentId, ...pushingAbsoluteLayout} = absoluteLayoutsByTopAsc[i]
        const hasAnchor = hasBottomTopAnchor(pushedAbsoluteLayout, pushingAbsoluteLayout)
        foundAnchors = foundAnchors || hasAnchor

        if (hasAnchor && !gridLayouts[pushingComponentId].margins.bottom) {
            gridLayouts[pushingComponentId].margins.bottom = {
                value: pushedAbsoluteLayout.y - getBottom(pushingAbsoluteLayout),
                type: 'px'
            }
        } else if (!hasWedgeToComp && !foundAnchors && isBoundedY(pushedAbsoluteLayout, pushingAbsoluteLayout)) {
            hasWedgeToComp = true
            const marginTop = gridLayouts[pushingComponentId].margins.top as UnitSize
            gridLayouts[pushedComponentId].minDistanceFromRow = {
                startsAtRow: gridLayouts[pushingComponentId].gridArea.rowStart,
                distance: {
                    value: pushedAbsoluteLayout.y - pushingAbsoluteLayout.y + marginTop.value,
                    type: 'px'
                }
            }
        }
    }

    if (foundAnchors) {
        delete gridLayouts[pushedComponentId].minDistanceFromRow
    } else if (!hasWedgeToComp) {
        gridLayouts[pushedComponentId].minDistanceFromRow = {
            startsAtRow: 1,
            distance: {value: pushedAbsoluteLayout.y, type: 'px'}
        }
    }
}

const calculateBottomRelations = (
    absoluteLayoutsByTopAsc: AbsoluteLayoutWithCompId[],
    gridLayouts: Record<string, MeshGridItemLayout>,
    bottomRelationsCalculationInfo: BottomRelationsCalculationInfo[]
): void => {
    for (const {absoluteLayoutWithCompId, lastCompToConsider} of bottomRelationsCalculationInfo) {
        calculateBottomRelationsForComp(
            absoluteLayoutWithCompId,
            absoluteLayoutsByTopAsc,
            lastCompToConsider,
            gridLayouts
        )
    }
}

const setEndRows = (
    absoluteLayoutsByBottomDesc: AbsoluteLayoutWithCompId[],
    gridLayouts: Record<string, MeshGridItemLayout>,
    rowThreshold: RowThresholdItem[]
): void => {
    for (const {componentId, ...layout} of absoluteLayoutsByBottomDesc) {
        if (!gridLayouts[componentId].margins.bottom) {
            gridLayouts[componentId].margins.bottom = {
                value: 10,
                type: 'px'
            }
        }

        const marginBottomObject = gridLayouts[componentId].margins.bottom as UnitSize
        const marginBottom = Math.min(marginBottomObject.value, 0)

        while (
            getBottom(layout) + marginBottom <= rowThreshold[rowThreshold.length - 1].start &&
            gridLayouts[componentId].gridArea.rowStart < rowThreshold[rowThreshold.length - 1].row
        ) {
            rowThreshold.pop()
        }

        gridLayouts[componentId].gridArea.rowEnd = rowThreshold[rowThreshold.length - 1].row + 1
    }
}

const calculateGrid = (absoluteLayouts: AbsoluteLayoutWithCompId[]): Record<string, MeshGridItemLayout> | undefined => {
    if (!absoluteLayouts || absoluteLayouts.length === 0) {
        return
    }

    const absoluteLayoutsByTopAsc = [...absoluteLayouts].sort(
        (absoluteLayoutComp1: AbsoluteLayout, absoluteLayoutComp2: AbsoluteLayout) =>
            absoluteLayoutComp1.y - absoluteLayoutComp2.y
    )
    const absoluteLayoutsByBottomDesc = [...absoluteLayouts]
        .sort(
            (absoluteLayoutComp1: AbsoluteLayout, absoluteLayoutComp2: AbsoluteLayoutWithCompId) =>
                getBottom(absoluteLayoutComp1) - getBottom(absoluteLayoutComp2)
        )
        .reverse()
    const {gridLayouts, rowsThreshold, bottomRelationsCalculationInfo} = createRows(absoluteLayoutsByTopAsc)
    calculateBottomRelations(absoluteLayoutsByTopAsc, gridLayouts, bottomRelationsCalculationInfo)
    setEndRows(absoluteLayoutsByBottomDesc, gridLayouts, rowsThreshold)

    return gridLayouts
}

const getAbsoluteLayoutsToConsiderForGrid = (
    componentsIds: string[],
    compsStructures: Record<string, StructureToConvertToMesh>,
    conversionResults: Record<string, MeshConversionResult>,
    layoutType: 'mobile' | 'desktop'
): AbsoluteLayoutWithCompId[] => {
    const currentLayoutType = layoutType === 'mobile' ? layoutType : 'default'

    return componentsIds.reduce(
        (absoluteLayoutsWithCompId: AbsoluteLayoutWithCompId[], childrenId: string): AbsoluteLayoutWithCompId[] => {
            const itemLayout = conversionResults[childrenId]?.[currentLayoutType]?.layout?.itemLayout as AnyItemLayout

            if (
                compsStructures[childrenId]?.[layoutType] &&
                !compsStructures[childrenId]?.[layoutType]?.layout.fixedPosition &&
                itemLayout.type === 'MeshItemLayout'
            ) {
                absoluteLayoutsWithCompId.push({
                    ...compsStructures[childrenId][layoutType]!.layout,
                    componentId: childrenId
                })
            }

            return absoluteLayoutsWithCompId
        },
        []
    )
}

export const calculateGridForContainer = (
    compsStructures: Record<string, StructureToConvertToMesh>,
    conversionResults: Record<string, MeshConversionResult>
): void => {
    const layoutTypes = ['default', 'mobile']

    for (const componentId of Object.keys(compsStructures)) {
        for (const layoutType of layoutTypes) {
            const currentLayoutType = layoutType === 'mobile' ? layoutType : 'desktop'
            const currentStructure: ComponentStructureVariant | undefined =
                compsStructures[componentId][currentLayoutType]

            if (!currentStructure) {
                continue
            }

            const {components} = currentStructure

            if (!components?.length) {
                continue
            }

            const filteredAbsoluteLayoutsWithCompId: AbsoluteLayoutWithCompId[] = getAbsoluteLayoutsToConsiderForGrid(
                components,
                compsStructures,
                conversionResults,
                currentLayoutType
            )

            const gridLayoutForComps = calculateGrid(filteredAbsoluteLayoutsWithCompId)

            for (const {componentId: childrenId} of filteredAbsoluteLayoutsWithCompId) {
                const itemLayout = conversionResults[childrenId][layoutType].layout.itemLayout as AnyItemLayout
                Object.assign(itemLayout, gridLayoutForComps![childrenId])
            }
        }
    }
}

export const calculateGridByAbsoluteLayouts = (
    componentsAbsoluteLayouts: Record<string, [CompRef, AbsoluteLayout]>
): Record<string, MeshGridItemLayout> | undefined => {
    const absoluteLayouts: AbsoluteLayoutWithCompId[] = Object.values(componentsAbsoluteLayouts).map(
        ([compPointer, absoluteLayout]) => ({
            ...absoluteLayout,
            componentId: compPointer.id
        })
    )

    return calculateGrid(absoluteLayouts)
}
