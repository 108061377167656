'use strict'
const _ = require('lodash')
const {convertToMesh} = require('@wix/document-manager-utils')
const {stripHashIfExists} = require('../helpers/dataUtils')

const DESKTOP = 'DESKTOP'
const MOBILE = 'MOBILE'
const VARIANTS_DATA_NAMESPACE = 'variants_data'
const LAYOUT_DATA_NAMESPACE = 'layout_data'
const MOBILE_VARIANT_ID = 'MOBILE-VARIANT'
const componentsDefinitionsMap = require('@wix/document-services-json-schemas/src/originalSchemas/componentsDefinitionsMap.json')

const PAGE_TYPES = ['Document', 'Page']
function getChildren(structure, viewMode) {
    if (structure.type === 'Document' && viewMode !== MOBILE) {
        return structure.children
    }
    if (PAGE_TYPES.includes(structure.type) && viewMode === MOBILE) {
        return structure.mobileComponents
    }
    return structure.components
}

function getAllComponents(structure, viewMode) {
    const stack = [structure]
    const componentsMap = {}

    while (stack.length > 0) {
        const currentStructure = stack.pop()
        componentsMap[currentStructure.id] = currentStructure
        const children = getChildren(currentStructure, viewMode)

        _.forEach(children, child => {
            child.parent = currentStructure.id
            stack.push(child)
        })
    }

    return componentsMap
}

function addVariantsDataIfNotExists(pageJson) {
    if (!pageJson.data[VARIANTS_DATA_NAMESPACE]) {
        pageJson.data[VARIANTS_DATA_NAMESPACE] = {}
    }
}

function createPropsBuilder(uniqueIdGenerator) {
    return function (compType) {
        const propsType = _.find(componentsDefinitionsMap[compType].propertyTypes)
        return {
            id: uniqueIdGenerator.getUniqueId('propItem', '-', {bucket: 'props'}),
            type: propsType
        }
    }
}

const getStructureVariant = (comp, pageJson) => {
    return {
        props: {
            ...pageJson.data.component_properties[stripHashIfExists(comp.propertyQuery)]
        },
        layout: {...comp.layout},
        parent: comp.parent,
        components: _.map(comp.components, 'id')
    }
}

const getStructureLayout = (componentId, {allComponents, pageJson}) => {
    const {structure} = pageJson
    const desktop = allComponents[DESKTOP][componentId]
    const mobile = allComponents[MOBILE][componentId]
    const structureToCheck = desktop ? desktop : mobile
    const structureLayout = {
        componentType: structureToCheck.componentType,
        pageId: structure.id,
        componentId,
        type: structureToCheck.type
    }
    if (desktop && desktop.layout) {
        structureLayout.desktop = getStructureVariant(desktop, pageJson)
    }
    if (mobile && mobile.layout) {
        structureLayout.mobile = getStructureVariant(mobile, pageJson)
    }
    return structureLayout
}

const generateLayoutId = uniqueIdGenerator => uniqueIdGenerator.getUniqueId('layout', '-', {bucket: 'layout'})
const createLayoutAndAddToPage =
    layoutBuilder =>
    ({pageJson, uniqueIdGenerator}, layoutData) => {
        const id = generateLayoutId(uniqueIdGenerator)
        const layout = layoutBuilder(id, layoutData)
        pageJson.data[LAYOUT_DATA_NAMESPACE][id] = layout
        return layout
    }
const createLayoutRefArray = createLayoutAndAddToPage(id => ({
    id,
    type: 'RefArray',
    values: []
}))
const createLayoutItem = createLayoutAndAddToPage((id, layoutData) => ({
    id,
    ...layoutData
}))
const createMobileLayoutRelation = createLayoutAndAddToPage((id, relationData) => {
    return {
        id,
        type: 'VariantRelation',
        variants: [`#${MOBILE_VARIANT_ID}`],
        ...relationData
    }
})

const addLayoutToComponent = ({allComponents}, componentId, layoutId) => {
    const desktopComponent = allComponents[DESKTOP][componentId]
    if (desktopComponent) {
        desktopComponent.layoutQuery = `#${layoutId}`
    }
    const mobileComponent = allComponents[MOBILE][componentId]
    if (mobileComponent) {
        mobileComponent.layoutQuery = `#${layoutId}`
    }
}

const writeMeshLayout = (conversionResult, componentId, conversionContext) => {
    const refArray = createLayoutRefArray(conversionContext)
    if (conversionResult.default.layout) {
        const defaultLayout = createLayoutItem(conversionContext, conversionResult.default.layout)
        refArray.values.push(`#${defaultLayout.id}`)
    }

    if (conversionResult.mobile.layout) {
        const mobileLayout = createLayoutItem(conversionContext, conversionResult.mobile.layout)
        const mobileRelation = createMobileLayoutRelation(conversionContext, {
            to: `#${mobileLayout.id}`,
            from: `#${componentId}`
        })
        refArray.values.push(`#${mobileRelation.id}`)
    }

    addLayoutToComponent(conversionContext, componentId, refArray.id)
}

const handleLayout = (convertedLayouts, conversionContext) => {
    _.forEach(convertedLayouts, (conversionResult, componentId) => {
        writeMeshLayout(conversionResult, componentId, conversionContext)
    })
}

const convertToMeshLayout = conversionContext => {
    const {allComponents, uniqueIdGenerator} = conversionContext
    const uniqueComponentsIds = _.union(Object.keys(allComponents[DESKTOP]), Object.keys(allComponents[MOBILE]))
    const structureToConvertToMesh = _(uniqueComponentsIds)
        .keyBy()
        .mapValues(componentId => getStructureLayout(componentId, conversionContext))
        .value()

    const convertedToMeshStructure = {}
    // @ts-ignore
    convertToMesh(structureToConvertToMesh, convertedToMeshStructure, {
        createPropertiesItemByType: createPropsBuilder(uniqueIdGenerator)
    })
    handleLayout(convertedToMeshStructure, conversionContext)
}

const createZeroLayout = () => ({x: 0, y: 0, height: 0, width: 0})
function convertPageCompsToZeroLayout({allComponents}) {
    _.forEach(allComponents, componentsInViewMode => {
        _.forEach(componentsInViewMode, component => {
            component.layout = createZeroLayout()
        })
    })
}

module.exports = {
    name: 'meshDataFixer',
    version: 0,
    experimentalVersions: [
        {version: 1, experiment: 'dm_meshLayoutPublic'},
        {version: 2, experiment: 'dm_zeroAbsoluteLayoutPublic'}
    ],
    exec(pageJson, pageIdsArray, magicObject) {
        if (
            !magicObject.isExperimentOpen('dm_meshLayoutPublic') ||
            (pageJson.data[LAYOUT_DATA_NAMESPACE] && Object.keys(pageJson.data[LAYOUT_DATA_NAMESPACE]).length > 0)
        ) {
            return
        }

        const {structure} = pageJson
        pageJson.data[LAYOUT_DATA_NAMESPACE] = {}
        addVariantsDataIfNotExists(pageJson)
        const conversionContext = {
            allComponents: {
                [DESKTOP]: getAllComponents(structure, DESKTOP),
                [MOBILE]: getAllComponents(structure, MOBILE)
            },
            pageJson,
            uniqueIdGenerator: magicObject.dataFixerUtils.uniqueIdGenerator
        }

        convertToMeshLayout(conversionContext)

        if (magicObject.isExperimentOpen('dm_zeroAbsoluteLayoutPublic')) {
            convertPageCompsToZeroLayout(conversionContext)
        }
    }
}
