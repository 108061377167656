import type {StructureToConvertToMesh, MeshConversionResult, ConversionStep, ConversionContext} from './types'
import {convertStructureLayoutToMesh} from './plugins/common'
import {calculateGridForContainer} from './plugins/grid'
import {convertScaleToProps} from './plugins/props'
import {fixBrokenLayouts} from './plugins/absoluteLayoutFixers'
export {calculateGridByAbsoluteLayouts} from './plugins/grid'

const conversionSteps = [fixBrokenLayouts, convertStructureLayoutToMesh, calculateGridForContainer, convertScaleToProps]

export const convertToMesh: ConversionStep = (
    structureToConvertToMesh: Record<string, StructureToConvertToMesh>,
    convertedToMeshStructure: Record<string, MeshConversionResult>,
    conversionContext: ConversionContext
): void => {
    for (const [componentId] of Object.entries(structureToConvertToMesh)) {
        convertedToMeshStructure[componentId] = {
            default: {},
            mobile: {}
        }
    }

    for (const conversion of conversionSteps) {
        conversion(structureToConvertToMesh, convertedToMeshStructure, conversionContext)
    }
}
