'use strict'

const _ = require('lodash')

const isJsonForPage = _.matchesProperty(['structure', 'type'], 'Page')
const isMasterPage = _.matchesProperty(['structure', 'type'], 'Document')

module.exports = {
    name: 'pageUriSeoFixer',
    version: 1,
    disableFixerAfterFirstRun: true,
    experimentalVersions: [
        {version: 2, experiment: 'dm_runTranslationsPageUriSeoFixer'},
        {version: 2, experiment: 'dm_runTranslationsPageUriSeoFixer_loggedIn'}
    ],
    exec(pageJson, pageIdsArray, magicObject) {
        if (_.get(magicObject, 'isSlash') && !isJsonForPage(pageJson)) {
            _.forEach(magicObject.pageIdToResolvedUriSEO, (resolved, pageId) => {
                if (pageJson.data.document_data[pageId]) {
                    pageJson.data.document_data[pageId].pageUriSEO = resolved.curr
                }
            })
        }

        if (
            magicObject.isExperimentOpen('dm_runTranslationsPageUriSeoFixer') ||
            magicObject.isExperimentOpen('dm_runTranslationsPageUriSeoFixer_loggedIn')
        ) {
            if (isMasterPage(pageJson)) {
                const translations = _.get(pageJson, 'translations')
                if (translations) {
                    _.forOwn(translations, languageData => {
                        _(languageData.data.document_data)
                            .pick(pageIdsArray)
                            .forOwn((pageData, pageId) => {
                                languageData.data.document_data[pageId].pageUriSEO =
                                    pageJson.data.document_data[pageId].pageUriSEO
                            })
                    })
                }
            }
        }
    }
}
