import type {
    Dock,
    Docking,
    MeshItemLayout,
    SingleLayoutData,
    ComponentLayout,
    UnitSize,
    FixedItemLayout,
    AbsoluteLayout
} from '@wix/document-services-types'
import type {MeshConversionResult, StructureToConvertToMesh, ComponentStructureVariant, ConversionStep} from '../types'
import {isNumber} from 'lodash'

const pageTypes = new Set(['mobile.core.components.Page', 'mobile.core.components.MasterPage'])
const containerTypesSet = new Set(['Page', 'Container', 'Document', 'RepeaterContainer', 'RefComponent'])
const forceFixedLayoutTypes = new Set(['platform.components.AppController'])

const isPage = (componentType: string) => pageTypes.has(componentType)
const isContainer = (type: string) => containerTypesSet.has(type)

interface ConversionConfig {
    fullWidthThreshold: number
}

const desktopConversionConfig: ConversionConfig = {
    fullWidthThreshold: 980
}

const mobileConversionConfig: ConversionConfig = {
    fullWidthThreshold: 320
}
const getDock = ({px}: Dock, isAbsolute: boolean = false): UnitSize | undefined => {
    if (isNumber(px)) {
        return {type: 'px', value: isAbsolute ? Math.abs(px) : px}
    }
}

const createMeshItemLayout = ({docked, x}: AbsoluteLayout): Partial<MeshItemLayout> => ({
    left: {value: x, type: 'px'},
    ...(docked?.left && {left: getDock(docked.left)}),
    ...(docked?.right && {right: getDock(docked.right)}),
    type: 'MeshItemLayout'
})

const createComponentLayout = (
    {layout}: ComponentStructureVariant,
    type: string,
    componentType: string,
    {fullWidthThreshold}: ConversionConfig
): ComponentLayout => {
    if (isPage(componentType)) {
        return {
            type: 'ComponentLayout',
            height: {
                type: 'auto'
            }
        }
    }

    const {height, width, rotationInDegrees, fixedPosition, docked} = layout
    const isFullWidth = width >= fullWidthThreshold
    return {
        type: 'ComponentLayout',
        rotationInDegrees: rotationInDegrees || 0,
        ...(isContainer(type) && {
            minHeight: {
                type: 'px',
                value: height
            },
            height: {
                type: 'auto'
            }
        }),
        ...(!isContainer(type) && {
            height: {
                type: 'px',
                value: height
            }
        }),
        width: {
            type: (!fixedPosition && docked) || isFullWidth ? 'percentage' : 'px',
            value: (!fixedPosition && docked) || isFullWidth ? 100 : width
        }
    }
}

const createFixedItemLayout = ({vCenter, hCenter, left, right, top, bottom}: Docking): Omit<FixedItemLayout, 'id'> => ({
    alignSelf: vCenter ? 'center' : 'start',
    justifySelf: hCenter ? 'center' : 'start',
    margins: {
        ...(left && {
            left: getDock(left)
        }),
        ...(right && {
            right: getDock(right)
        }),
        ...(bottom && {
            bottom: getDock(bottom)
        }),
        ...(top && {
            top: getDock(top)
        }),
        ...(vCenter && {
            ...(vCenter.px! < 0 ? {bottom: getDock(vCenter, true)} : {top: getDock(vCenter)})
        }),
        ...(hCenter && {
            ...(hCenter.px! < 0 ? {right: getDock(hCenter, true)} : {left: getDock(hCenter)})
        })
    },
    top: {
        type: 'px',
        value: 0
    },
    bottom: {
        type: 'auto'
    },
    left: {
        type: 'px',
        value: 0
    },
    right: {
        type: 'auto'
    },
    type: 'FixedItemLayout'
})

const createItemLayout = (
    {layout}: ComponentStructureVariant,
    componentType: string
): MeshItemLayout | Omit<FixedItemLayout, 'id'> | {} => {
    if (isPage(componentType)) {
        return {}
    }

    if (forceFixedLayoutTypes.has(componentType)) {
        layout.fixedPosition = true
        layout.docked = {
            top: {px: 150},
            hCenter: {px: layout.x}
        }
    }

    if (layout.fixedPosition && layout.docked) {
        return createFixedItemLayout(layout.docked)
    }

    return createMeshItemLayout(layout)
}

export const convertCommon = (
    componentStructureVariant: ComponentStructureVariant,
    type: string,
    componentType: string,
    conversionConfig: ConversionConfig
) => {
    const containerLayout =
        type === 'Component'
            ? {}
            : {
                  type: 'MeshContainerLayout'
              }
    const itemLayout = createItemLayout(componentStructureVariant, componentType)
    const componentLayout = createComponentLayout(componentStructureVariant, type, componentType, conversionConfig)

    const singleItemLayout: Omit<SingleLayoutData, 'id'> = {
        componentLayout,
        containerLayout,
        itemLayout,
        type: 'SingleLayoutData'
    }
    return singleItemLayout
}

export const convertLayout = (
    {desktop, mobile, type, componentType}: StructureToConvertToMesh,
    result: MeshConversionResult
) => {
    if (desktop) {
        result.default.layout = convertCommon(desktop, type, componentType, desktopConversionConfig)
    }

    if (mobile) {
        result.mobile.layout = convertCommon(mobile, type, componentType, mobileConversionConfig)
    }
}

export const convertStructureLayoutToMesh: ConversionStep = (
    structureLayouts: Record<string, StructureToConvertToMesh>,
    convertedLayouts: Record<string, MeshConversionResult>
): void => {
    for (const [componentId, structureLayout] of Object.entries(structureLayouts)) {
        convertLayout(structureLayout, convertedLayouts[componentId])
    }
}
