import type {StructureToConvertToMesh} from '../types'
import type {AbsoluteLayout} from '@wix/document-services-types'
import _ from 'lodash'

const getNewValue = (value: number, allowNegative: boolean, fallbackValue: number) => {
    if (_.isNil(value) || _.isNaN(value) || !_.isNumber(value) || (!allowNegative && value < 0)) {
        return fallbackValue
    }

    return value
}

const fixProperty = (layout: AbsoluteLayout, prop: string, allowNegative: boolean, fallbackValue: number) => {
    layout[prop] = getNewValue(layout[prop], allowNegative, fallbackValue)
}

const fixBrokenLayout = (layout: AbsoluteLayout | undefined) => {
    if (!layout) {
        return
    }

    fixProperty(layout, 'x', true, 0)
    fixProperty(layout, 'y', true, 0)
    fixProperty(layout, 'height', false, 1)
    fixProperty(layout, 'width', false, 1)
}

export const fixBrokenLayouts = (structureToConvertToMesh: Record<string, StructureToConvertToMesh>) => {
    for (const [componentId, compStructure] of Object.entries(structureToConvertToMesh)) {
        if (componentId === 'masterPage') {
            return
        }

        fixBrokenLayout(compStructure.desktop?.layout)
        fixBrokenLayout(compStructure.mobile?.layout)
    }
}
