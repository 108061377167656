import type {StructureToConvertToMesh, MeshConversionResult, ConversionStep, ConversionContext} from '../types'

export const convertScaleToProps: ConversionStep = (
    structureToConvertToMesh: Record<string, StructureToConvertToMesh>,
    convertedStructure: Record<string, MeshConversionResult>,
    conversionContext: ConversionContext
): void => {
    for (const [componentId, structureToConvert] of Object.entries(structureToConvertToMesh)) {
        const {mobile} = structureToConvert
        if (!mobile?.layout?.scale || mobile.layout.scale === 1) {
            return
        }

        const propItem = conversionContext.createPropertiesItemByType(structureToConvert.componentType)
        convertedStructure[componentId]!.mobile!.props = {
            ...propItem,
            ...structureToConvert.mobile?.props,
            fontScale: mobile.layout.scale
        }
    }
}
